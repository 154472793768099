import * as React from "react"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spacing,
  TextField,
  OutlinedButton,
} from "../components/base"

import {useInput} from "../hooks/inputs"

/**
 * @type {React.FunctionComponent<SettingsModalProps & Omit<import('./base/modal').ModalProps, "onCloseClick">>}
 */
const SettingsModal = ({
  onCancel,
  isLoading,
  apiKey = "",
  surveyId = "",
  title = "Settings",
  onSave,
  apiKeyError = false,
  surveyIdError = false,
  surveyInputLabel = "Survey ID",
  onInputsChanged,
  ...modalProps
}) => {
  // add single mode
  const apiKeyInput = useInput({
    defaultValue: apiKey,
  })
  const surveyIDInput = useInput({defaultValue: surveyId})

  const onCancelHandler = () => {
    // reset inputs
    apiKeyInput.setText(apiKey)
    surveyIDInput.setText(surveyId)
    onCancel && onCancel()
  }

  // whether to disable the "Save" button
  const saveButtonDisabled = !apiKeyInput.value || !surveyIDInput.value

  return (
    <Modal
      {...modalProps}
      onCloseClick={onCancelHandler}
      closeDisabled={isLoading}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="edit-block">
          <TextField
            {...apiKeyInput.getProps({
              "aria-label": "dropthought API key",
              tabIndex: 0,
              disabled: isLoading,
              onChange: onInputsChanged,
              error: apiKeyError ? "The api key is invalid" : undefined,
            })}
          />
          <Spacing spacing="l" />
          <TextField
            {...surveyIDInput.getProps({
              "aria-label": surveyInputLabel,
              tabIndex: 0,
              disabled: isLoading,
              onChange: onInputsChanged,
              error: surveyIdError ? "The survey id is invalid" : undefined,
            })}
          />
        </div>
      </ModalBody>
      <ModalFooter className="row">
        <OutlinedButton onClick={onCancelHandler}>Cancel</OutlinedButton>
        <Button
          tabIndex="0"
          disabled={saveButtonDisabled}
          onClick={() => {
            if (apiKeyInput.value && surveyIDInput.value && onSave) {
              onSave({
                apiKey: apiKeyInput.value,
                surveyId: surveyIDInput.value,
              })
            }
          }}
          loading={isLoading}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SettingsModal

/**
 * @typedef {object} SettingsModalProps
 * @property {() => void} onCancel
 * @property {boolean=} isLoading
 * @property {(setting: {apiKey: string, surveyId: string}) => void} onSave
 * @property {string=} apiKey
 * @property {string=} title
 * @property {string=} surveyId
 * @property {string=} surveyInputLabel
 * @property {()=>void=} onInputsChanged
 * @property {boolean=} apiKeyError
 * @property {boolean=} surveyIdError
 */
