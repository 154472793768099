import {useQuery, useMutation, queryClient} from "../hooks/react-query"

import {getIntervalSettings, updateInterval} from "../lib/ct-api/interval"

const INTERVAL_QUERY_KEY = ["ct-interval"]

/**
 * @template TData, TVariables, TContext
 * @typedef {import('../hooks/react-query').UseMutationOptions<TData, FetchError, TVariables, TContext>} UseMutationOptions
 */

/**
 * @param {import('../hooks/react-query').UseQueryOptions<IntervalSettings, FetchError} config
 */
export function useIntervalSettings(config = {}) {
  return useQuery({
    queryKey: INTERVAL_QUERY_KEY,
    queryFn: () => getIntervalSettings(),
    ...config,
  })
}

/**
 * @template TResult, UpdateIntervalParam, TSnapshot
 * @param {UseMutationOptions<TResult, UpdateIntervalParam, TSnapshot>} config
 */
export function useUpdateInterval(config = {}) {
  const {onSuccess, ...restConfig} = config
  return useMutation(updateInterval, {
    ...restConfig,
    errorMessage: "Oops, something went wrong. Please try again later.",
    successMessage: "Setting is updated successfully.",
    onSuccess: async (data, variables, context) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(INTERVAL_QUERY_KEY)

      // update the query cache with the updated data
      queryClient.setQueryData(INTERVAL_QUERY_KEY, oriData => ({
        ...oriData,
        ...variables,
      }))

      // Always refetch after success:
      queryClient.invalidateQueries(INTERVAL_QUERY_KEY)

      onSuccess && onSuccess(data, variables, context)
    },
  })
}

/**
 * @typedef {import('../domains/ct-interval').UpdateIntervalParam} UpdateIntervalParam
 * @typedef {import('../domains/ct-interval').IntervalSettings} IntervalSettings
 * @typedef {import('../lib/fetch').FetchError} FetchError
 */
