import * as React from "react"
import classNames from "classnames"

import {TextField} from "./text-field"

/**
 * @typedef {object} NumberInputOwnProps
 * @property {number=} min
 * @property {number=} max
 * @property {string=} unitLabel
 *
 * @typedef {import('./text-field').TextFieldProps} TextFieldProps
 * @typedef {TextFieldProps & NumberInputOwnProps} NumberInputProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLInputElement, NumberInputProps>}
 */
const NumberInputForwardRefRender = ({unitLabel, className, ...props}, ref) => {
  return (
    <TextField
      {...props}
      className={classNames("sw-number-input", className)}
      ref={ref}
      type="number"
      endAdornment={
        unitLabel ? <span className="unit-label">{unitLabel}</span> : null
      }
    />
  )
}

/**
 * @type {React.ForwardRefExoticComponent<NumberInputProps>}
 */
export const NumberInput = React.forwardRef(NumberInputForwardRefRender)
