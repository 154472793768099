import * as React from "react"

import {TablePlaceholder} from "../components/base/table"
import CategoryTable from "../components/category-table"

import {useCategoryTypes} from "../services/category"

import EditCategoryTypeModal from "./edit-category-type-modal-container"

/**
 * @param {Category} category
 */
const getPlaceholderNum = category => {
  if (category === "eligibilityStatus") return 2
  else if (category === "workStatus") return 7
  else return 3
}

/**
 * @type {React.FunctionComponent<CategoryTableContainerProps>}
 * @param {CategoryTableContainerProps} props
 */
const CategoryTableContainer = ({category}) => {
  const {isLoading, data: categoryTypes} = useCategoryTypes(category.category)

  /** @type {[CategoryTypeSetting, React.Dispatch<React.SetStateAction<CategoryTypeSetting>>]} */
  const [categoryTypeInEdit, setCategoryTypeInEdit] = React.useState()

  return (
    <React.Fragment key={category.category}>
      <h2>{category.name}</h2>
      {isLoading ? (
        <TablePlaceholder num={getPlaceholderNum(category.category)} />
      ) : (
        <CategoryTable
          category={category}
          categoryTypes={categoryTypes}
          onEditClick={setCategoryTypeInEdit}
        />
      )}

      <EditCategoryTypeModal
        categoryInfo={category}
        categoryTypeSetting={categoryTypeInEdit}
        show={!!categoryTypeInEdit}
        onCancel={() => setCategoryTypeInEdit(undefined)}
        onCloseClick={() => setCategoryTypeInEdit(undefined)}
      />
    </React.Fragment>
  )
}

export default CategoryTableContainer

/**
 * @typedef {object} CategoryTableContainerProps
 * @property {WorkspaceCategoryInfo} category
 */

/**
 * @typedef {import("../domains/category").CategoryTypeSetting} CategoryTypeSetting
 * @typedef {import("../domains/category").WorkspaceCategoryInfo} WorkspaceCategoryInfo
 * @typedef {import("../domains/category").Category} Category
 */
