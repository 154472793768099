import * as React from "react"

import CTSettingsTable from "../components/ct-settings-table"

import EditUploadTimeModal from "./edit-ct-upload-time-modal-container"

/**
 * @typedef {object} CTSettingsTableContainerProps
 * @property {IntervalSettings} intervalSettings
 */

/**
 * @type {React.FunctionComponent<CTSettingsTableContainerProps>}
 * @param {CTSettingsTableContainerProps} props
 */
const CTSettingsTableContainer = ({intervalSettings}) => {
  /** @type {[IntervalType, React.Dispatch<React.SetStateAction<IntervalType>>]} */
  const [intervalToEdit, setIntervalToEdit] = React.useState()
  const closeModal = React.useCallback(() => {
    setIntervalToEdit(undefined)
  }, [])

  return (
    <>
      <h2>Contact Tracing</h2>

      <CTSettingsTable
        intervalSettings={intervalSettings}
        onEditUploadIntervalClick={() => setIntervalToEdit("uploadInterval")}
        onEditUpdateIntervalClick={() => setIntervalToEdit("updateInterval")}
      />

      {intervalToEdit ? (
        <EditUploadTimeModal
          type={intervalToEdit}
          intervalSettings={intervalSettings}
          onCancel={closeModal}
        />
      ) : undefined}
    </>
  )
}

export default CTSettingsTableContainer

/**
 * @typedef {import('../domains/ct-interval').UpdateIntervalParam} UpdateIntervalParam
 * @typedef {import('../domains/ct-interval').IntervalSettings} IntervalSettings
 * @typedef {import('../domains/ct-interval').IntervalType} IntervalType
 */
