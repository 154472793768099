import authoredFetch from "../aws/amplify-fetch"

import {DEFAULT_BASEURL, INTERVAL_ENDPOINT} from "./endpoints"

/**
 * @param {{
 * }} param0
 * @returns {Promise<IntervalSettings>}
 */
export async function getIntervalSettings() {
  return authoredFetch(INTERVAL_ENDPOINT, {
    baseURL: DEFAULT_BASEURL,
    method: "GET",
  }).then(({data}) => data)
}

/**
 * @param {UpdateIntervalParam} data
 * @returns {Promise<IntervalSettings>}
 */
export async function updateInterval(data = {}) {
  return authoredFetch(INTERVAL_ENDPOINT, {
    baseURL: DEFAULT_BASEURL,
    method: "POST",
    data,
  }).then(({data}) => data)
}

/**
 * @typedef {import('../domains/ct-interval').UpdateIntervalParam} UpdateIntervalParam
 * @typedef {import('../domains/ct-interval').IntervalSettings} IntervalSettings
 */
