import {isNull} from "../../utils/is-null"

export function numberValidator({text: number, min, max, required = false}) {
  if (isNull(number) || number === "") {
    if (required) return "mandatory input"
  } else {
    if (isNaN(number)) {
      return `The value only allows integer within ${min} to ${max}.`
    }
    if (!isNull(min) && parseFloat(number) < min) {
      return `The value only allows integer within ${min} to ${max}.`
    }
    if (!isNull(max) && parseFloat(number) > max) {
      return `The value only allows integer within ${min} to ${max}.`
    }
  }

  return undefined
}

export function intValidator(param) {
  let error = numberValidator(param)
  if (!error && !Number.isInteger(parseFloat(param.text))) {
    return `The value only allows integer within ${param.min} to ${param.max}.`
  }

  return error
}
