import * as React from "react"

import EditCTUploadTimeModal from "../components/edit-ct-upload-time-modal"

import {useUpdateInterval} from "../services/ct-interval"

/**
 * @type {React.FunctionComponent<EditCTUploadTimeModalContainerProps>}
 */
const EditCTUploadTimeModalContainer = props => {
  const {mutate: updateInterval, isLoading} = useUpdateInterval({
    onSuccess: () => {
      props.onCancel()
    },
  })

  if (!props.intervalSettings) return null
  return (
    <EditCTUploadTimeModal
      {...props}
      show
      isLoading={isLoading}
      onCloseClick={props.onCancel}
      onUpdateStatus={updateInterval}
    />
  )
}

export default EditCTUploadTimeModalContainer

/**
 * @typedef {object} EditCTUploadTimeModalContainerProps
 * @property {import('../domains/ct-interval').IntervalType=} type
 * @property {() => void} onCancel
 * @property {import('../domains/category').WorkspaceCategoryInfo} categoryInfo
 * @property {IntervalSettings=} intervalSettings
 */

/**
 * @typedef {import('../domains/ct-interval').IntervalSettings} IntervalSettings
 */
