import * as React from "react"

import {TablePlaceholder} from "../components/base/table"
import DefaultPlaceholder from "../components/default-placeholder"

import SEO from "../layouts/seo"

import {CategoryString} from "../domains/category"
import {useIntervalSettings} from "../services/ct-interval"
import {useWorkspaceSettings} from "../services/workspace"

import CategoryTableContainer from "../containers/category-table-container"
import CTSettingsTableContainer from "../containers/ct-settings-table-container"
import ConfigureSurveys from "../containers/configure-surveys"

import "./app-settings.scss"
import "../styles/animation.scss"

const categories = [
  {
    category: CategoryString.eligibilityStatus,
    name: "Eligibility Status",
  },
  {
    category: CategoryString.workStatus,
    name: "Work Status",
  },
]

/**
 * @type {React.FunctionComponent<PageProps>}
 * @param {PageProps} props
 */
const SettingsPage = () => {
  const {
    isLoading: isIntervalLoading,
    data: intervalSettings,
    ...intervalSettingsQueryState
  } = useIntervalSettings()
  const {
    isLoading: isWorkspaceSettingsLoading,
    data: workspaceSettings,
    ...workspaceSettingsQueryState
  } = useWorkspaceSettings()

  return (
    <div className="app-settings-page-container dashboard-page-container">
      <SEO title="Employee Settings" />
      <h1 className="row">Employee Settings</h1>
      {isIntervalLoading || isWorkspaceSettingsLoading ? (
        <SettingsContentPlaceholder />
      ) : (
        <DefaultPlaceholder
          forceError
          error={
            intervalSettingsQueryState.error ||
            workspaceSettingsQueryState.error
          }
          data={intervalSettings}>
          {() => (
            <SettingsContent
              categories={categories}
              intervalSettings={intervalSettings}
              workspaceSettings={workspaceSettings}
            />
          )}
        </DefaultPlaceholder>
      )}
    </div>
  )
}

/**
 *
 * @param {{
 *  categories: import('../domains/category').WorkspaceCategoryInfo[]
 *  intervalSettings: import('../domains/ct-interval').IntervalSettings
 *  workspaceSettings: import('../domains/workspace-settings').WorkspaceSettings
 * }} param0
 */
const SettingsContent = ({
  categories = [],
  intervalSettings,
  workspaceSettings,
}) => {
  return (
    <>
      {categories.map(category => (
        <CategoryTableContainer key={category.category} category={category} />
      ))}
      <CTSettingsTableContainer intervalSettings={intervalSettings} />

      <ConfigureSurveys workspaceSettings={workspaceSettings} />
    </>
  )
}

const SettingsContentPlaceholder = () => {
  return (
    <>
      <h2>{categories[0].name}</h2>
      <TablePlaceholder num={2} />
      <h2>{categories[1].name}</h2>
      <TablePlaceholder num={7} />
      <h2>Contact Tracing</h2>
      <TablePlaceholder num={1} />
    </>
  )
}

export default SettingsPage

/**
 * @typedef {object} LocationState
 * @property {string=} name
 * @property {string=} id
 */
/**
 * @typedef {import('gatsby').PageProps<object, object, LocationState>} PageProps
 */
