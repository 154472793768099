import * as React from "react"
import classNames from "classnames"

import {Table} from "./base/table"
import {IconButton} from "./base/icon-button"
import {isNull} from "../utils/is-null"

/**
 * @typedef {import('../domains/category').CategoryTypeSetting} CategoryTypeSetting
 * @typedef {import('../domains/category').WorkspaceCategoryInfo} WorkspaceCategoryInfo
 */

/**
 * @typedef {object} CategoryTableProps
 * @property {WorkspaceCategoryInfo} category
 * @property {CategoryTypeSetting[]} categoryTypes
 * @property {(categoryTypeSetting: CategoryTypeSetting) => void} onEditClick
 */

/**
 * @type {React.FC<CategoryTableProps>}
 * @param {CategoryTableProps} param0
 */
const CategoryTable = ({category, categoryTypes, onEditClick}) => {
  const sample = categoryTypes[0]

  /**
   *
   * @param {React.MouseEvent<HTMLTableElement, MouseEvent>} event
   */
  const onClickHandler = event => {
    // track the enter-ct click, navigate to ct detail
    if (
      event.target.tagName === "BUTTON" &&
      event.target.getAttribute("data-name") === "edit-settings"
    ) {
      const categoryType =
        categoryTypes[event.target.getAttribute("data-index")]
      if (categoryType) {
        onEditClick(categoryType)
      }
    }
  }

  return (
    <Table
      style={{minWidth: 1150}}
      onClick={onClickHandler}
      aria-label="Settings Table">
      <thead>
        <tr>
          <th className="fixed-width">&nbsp;</th>
          <th style={{width: 290}}>{category.name}</th>
          <th style={{width: 240}}>Restriction Duration</th>
          <th style={{width: 240}}>Access Restriction</th>
          {isNull(sample.trigger) ? null : (
            <th style={{width: 240}}>Trigger Contact Tracer</th>
          )}
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {categoryTypes.map((categoryType, index) => (
          <tr key={categoryType.categoryType}>
            <td></td>
            <td>{categoryType.name}</td>
            <td
              className={classNames({
                "text-inactive-color": !categoryType.accessRestriction,
              })}>
              {renderRestrictedDuration(categoryType)}
            </td>
            <td className="text-inactive-color">
              {renderBoolean(categoryType.accessRestriction)}
            </td>
            {isNull(sample.trigger) ? null : (
              <td>{renderBoolean(categoryType.trigger)}</td>
            )}
            <td className="text-right">
              <IconButton
                icon="btn-edit-status"
                aria-label={`edit ${categoryType.name}`}
                size={24}
                data-name="edit-settings"
                data-index={index}
                data-value={categoryType.categoryType}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

/**
 * @param {CategoryTypeSetting} categoryType
 */
const renderRestrictedDuration = categoryType => {
  if (!categoryType.accessRestriction) {
    return "NA"
  }
  return `${categoryType.restrictedDuration} Days`
}

const renderBoolean = boolean => (boolean ? "Yes" : "No")

export default CategoryTable
