import * as React from "react"

import EditCategoryTypeModal from "../components/edit-category-type-modal"

import {useUpdateCategoryType} from "../services/category"

/**
 * @type {React.FunctionComponent<EditCategoryTypeModalContainerProps>}
 */
const EditCategoryTypeModalContainer = props => {
  const {mutate: updateCategoryType, isLoading} = useUpdateCategoryType({
    onSuccess: () => {
      props.onCancel()
    },
  })

  if (!props.categoryTypeSetting) return null
  return (
    <EditCategoryTypeModal
      {...props}
      show
      isLoading={isLoading}
      onCloseClick={props.onCancel}
      onUpdateStatus={updatedSettings => {
        // console.log("new settings", updatedSettings)
        updateCategoryType({
          categoryTypeSetting: updatedSettings,
        })
      }}
    />
  )
}

export default EditCategoryTypeModalContainer

/**
 * @typedef {object} EditCategoryTypeModalContainerProps
 * @property {() => void} onCancel
 * @property {import('../domains/category').WorkspaceCategoryInfo} categoryInfo
 * @property {CategoryTypeSetting=} categoryTypeSetting
 */

/**
 * @typedef {import('../domains/category').CategoryTypeSetting} CategoryTypeSetting
 * @typedef {import('../components/edit-category-type-modal').EditCategoryTypeModalProps} EditCategoryTypeModalProps
 */
