import * as React from "react"
import classNames from "classnames"

import {callAll} from "../../utils/call-all"

/**
 * @typedef {object} SwitchOwnProps
 * @property {boolean} on
 *
 * @typedef {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} RawButtonProps
 * @typedef {RawButtonProps & SwitchOwnProps} SwitchProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLButtonElement, SwitchProps>}
 */
const SwitchForwardRefRender = ({on, className = "", ...props}, ref) => {
  const btnClassName = classNames([
    className,
    "toggle-btn",
    on ? "toggle-btn-on" : "toggle-btn-off",
  ])

  return (
    <div className="flex">
      <input
        className="toggle-input"
        type="checkbox"
        checked={on}
        disabled={props.disabled}
        readOnly
      />
      <button
        ref={ref}
        className={btnClassName}
        aria-label="Toggle"
        {...props}
      />
    </div>
  )
}

/**
 * @type {React.ForwardRefExoticComponent<SwitchProps>}
 */
const Switch = React.forwardRef(SwitchForwardRefRender)

const useSwitch = ({defaultOn = false} = {}) => {
  const [on, setOn] = React.useState(defaultOn)
  const onToggle = React.useCallback(() => {
    setOn(v => !v)
  }, [setOn])

  /** @type {(props: Partial<SwitchProps>) => Partial<SwitchProps>} */
  const getProps = ({onClick, ...props} = {}) => ({
    on,
    ...props,
    onClick: callAll(onClick, onToggle),
  })

  return {
    on,
    onToggle,
    getProps,
  }
}

export {Switch, useSwitch}
