import * as React from "react"

import {DTSurveySettingsType} from "../domains/workspace-settings"

import {TextButton} from "../components/base/text-button"
import DropthoughtSettingsModal from "../components/dropthought-survey-settings-modal"

import {useModalProps} from "../hooks/modal"

import {useUpdateDTSurveySettings} from "../services/workspace"

const textButtonStyle = {
  fontSize: 12,
}

/**
 * @param {keyof DTSurveySettingsType} surveyType
 */
const displayTitleBySurveyType = surveyType => {
  if (surveyType === DTSurveySettingsType.backToWorkSurvey) {
    return "Back to Work Survey Settings"
  }
  return "Daily Wellness Survey Settings"
}

/**
 * @type {React.FC<ConfigureSurveysProps>}
 * @param {ConfigureSurveysProps} param0
 */
const ConfigureSurveys = ({workspaceSettings}) => {
  const surveySettingsModalProps = useModalProps()

  /** @type {[keyof DTSurveySettingsType, React.Dispatch<React.SetStateAction<keyof DTSurveySettingsType>>]} */
  const [surveyType, setSurveyType] = React.useState(
    DTSurveySettingsType.backToWorkSurvey,
  )

  const openModal = surveyTypeToOpen => () => {
    setSurveyType(surveyTypeToOpen)
    surveySettingsModalProps.openModal()
  }

  const {
    mutate: updateDTSurveySettings,
    isLoading,
    error,
    reset,
  } = useUpdateDTSurveySettings({
    onSuccess: surveySettingsModalProps.closeModal,
  })

  return (
    <div className="text-12 text-w-500 text-center text-color-brown-grey">
      Configure the Dropthought{" "}
      <TextButton
        noPadding
        style={textButtonStyle}
        onClick={openModal(DTSurveySettingsType.backToWorkSurvey)}>
        Back to Work Survey
      </TextButton>{" "}
      and{" "}
      <TextButton
        noPadding
        style={textButtonStyle}
        onClick={openModal(DTSurveySettingsType.dailySurvey)}>
        Daily Wellness Survey
      </TextButton>
      <DropthoughtSettingsModal
        {...surveySettingsModalProps.getProps()}
        isLoading={isLoading}
        title={displayTitleBySurveyType(surveyType)}
        onCancel={() => {
          reset()
          surveySettingsModalProps.closeModal()
        }}
        apiKey={workspaceSettings[surveyType]?.apiKey}
        surveyId={workspaceSettings[surveyType]?.surveyId}
        onInputsChanged={reset}
        apiKeyError={error && error.message === "Invalid api key"}
        surveyIdError={error && error.message === "Invalid survey id"}
        onSave={({apiKey, surveyId}) => {
          updateDTSurveySettings({
            apiKey,
            surveyId,
            surveyType: surveyType,
          })
        }}
      />
    </div>
  )
}

export default ConfigureSurveys

/**
 * @typedef {object} ConfigureSurveysProps
 * @property {import('../domains/workspace-settings').WorkspaceSettings} workspaceSettings
 */
