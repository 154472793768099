export type IntervalType = "updateInterval" | "uploadInterval"

export const IntervalTitle = {
  updateInterval: "Capture Time",
  uploadInterval: "Upload Time",
}

export type IntervalSettings = {
  updateInterval: number
  uploadInterval: number
}

export type UpdateIntervalParam = {
  updateInterval?: number
  uploadInterval?: number
}
