import * as React from "react"

import {Table} from "./base/table"
import {IconButton} from "./base/icon-button"

/**
 * @typedef {object} CTSettingsTableProps
 * @property {import('../domains/ct-interval').IntervalSettings} intervalSettings
 * @property {() => void} onEditUploadIntervalClick
 * @property {() => void} onEditUpdateIntervalClick
 */

/**
 * @type {React.FC<CTSettingsTableProps>}
 * @param {CTSettingsTableProps} param0
 */

const CTSettingsTable = ({
  intervalSettings,
  onEditUploadIntervalClick,
  onEditUpdateIntervalClick,
}) => {
  return (
    <Table style={{minWidth: 1150}}>
      <thead>
        <tr>
          <th className="fixed-width">&nbsp;</th>
          <th className="" style={{width: 290}}>
            Items
          </th>
          <th className="" style={{width: 250}}>
            Value
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>Capture Time</td>
          <td>{intervalSettings.updateInterval} mins</td>
          <td className="text-right">
            <IconButton
              icon="btn-edit-status"
              aria-label="edit contact tracer capture time"
              size={24}
              onClick={onEditUpdateIntervalClick}
            />
          </td>
        </tr>
        <tr hidden>
          <td></td>
          <td>Upload Time</td>
          <td>{intervalSettings.uploadInterval} mins</td>
          <td className="text-right">
            <IconButton
              icon="btn-edit-status"
              aria-label="edit contact tracer upload time"
              size={24}
              onClick={onEditUploadIntervalClick}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default CTSettingsTable
