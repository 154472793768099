export const callAll = (...fns) => (...args) =>
  fns.forEach(fn => fn && fn(...args))

export const allRef = (...refs) => node => {
  refs.forEach(ref => {
    if (ref && ref.hasOwnProperty("current")) {
      ref.current = node
    }
  })
}
