import * as React from "react"

import {Switch, useSwitch} from "../components/base/switch"
import {NumberInput} from "../components/base/number-input"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spacing,
  TextField,
  OutlinedButton,
} from "../components/base"

import {useInput} from "../hooks/inputs"
import {intValidator} from "../lib/validator/number"
import {isNull} from "../utils/is-null"

/**
 * @type {React.FunctionComponent<EditCategoryTypeModalProps>}
 */
const DurationInput = ({categoryTypeSetting, ...inputProps}) => {
  if (categoryTypeSetting.accessRestriction) {
    return <NumberInput {...inputProps} />
  } else {
    return (
      <TextField
        readOnly
        disabled
        aria-label="Restriction Duration"
        value="NA"
      />
    )
  }
}

/**
 * @type {React.FunctionComponent<EditCategoryTypeModalProps & import('../components/base/modal').ModalProps>}
 */
const EditCategoryTypeModal = ({
  onCancel,
  categoryInfo,
  categoryTypeSetting,
  isLoading = false,
  onUpdateStatus,
  ...modalProps
}) => {
  const durationInput = useInput({
    defaultValue: categoryTypeSetting.restrictedDuration.toString(),
    shouldDisplayError: () => true,
    validator: intValidator,
    validatorProps: {
      min: 1,
      max: 60,
    },
  })

  const triggerSwitch = useSwitch({
    defaultOn: categoryTypeSetting.trigger,
  })

  const notEditable =
    !categoryTypeSetting.accessRestriction &&
    isNull(categoryTypeSetting.trigger)

  const updateButtonDisabled =
    notEditable ||
    isLoading ||
    (categoryTypeSetting.accessRestriction && durationInput.error)

  return (
    <Modal {...modalProps} onCloseClick={onCancel} closeDisabled={isLoading}>
      <ModalHeader>{`Edit ${categoryInfo.name}`}</ModalHeader>
      <ModalBody>
        <div className="edit-block">
          <TextField
            readOnly
            disabled
            aria-label="Title"
            value={categoryTypeSetting.name}
          />
          <Spacing height={7} />

          <DurationInput
            {...durationInput.getProps({
              categoryTypeSetting,
              tabIndex: "0",
              required: true,
              "aria-label": "Restriction Duration",
              unitLabel: "Days",
              min: 1,
              max: 30,
            })}
          />
          <Spacing height={10} />
          <div className="row justify-between">
            <span className="text-box-label text-inactive-color ">
              Access Restriction
            </span>
            <Switch disabled on={categoryTypeSetting.accessRestriction} />
          </div>
          <Spacing height={30} />
          {isNull(categoryTypeSetting.trigger) ? null : (
            <div className="row justify-between">
              <span className="text-box-label">Trigger Contact Tracer</span>
              <Switch {...triggerSwitch.getProps({tabIndex: "0"})} />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="row">
        <OutlinedButton tabIndex="0" disabled={isLoading} onClick={onCancel}>
          Cancel
        </OutlinedButton>
        <Button
          disabled={updateButtonDisabled}
          width={160}
          tabIndex="0"
          onClick={() => {
            onUpdateStatus &&
              onUpdateStatus({
                ...categoryTypeSetting,
                restrictedDuration: parseInt(durationInput.value),
                trigger: isNull(categoryTypeSetting.trigger)
                  ? undefined
                  : triggerSwitch.on,
              })
          }}
          loading={isLoading}>
          Update Status
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditCategoryTypeModal

/**
 * @typedef {object} EditCategoryTypeModalProps
 * @property {() => void} onCancel
 * @property {boolean=} isLoading
 * @property {(categoryTypeSetting: CategoryTypeSetting) => void} onUpdateStatus
 * @property {WorkspaceCategoryInfo} categoryInfo
 * @property {CategoryTypeSetting=} categoryTypeSetting
 */

/**
 * @typedef {import('../domains/category').CategoryTypeSetting} CategoryTypeSetting
 * @typedef {import('../domains/category').WorkspaceCategoryInfo} WorkspaceCategoryInfo
 */
