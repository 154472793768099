import * as React from "react"

import {NumberInput} from "./base/number-input"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  OutlinedButton,
} from "./base"

import {IntervalTitle} from "../domains/ct-interval"
import {useInput} from "../hooks/inputs"
import {intValidator} from "../lib/validator/number"

/**
 * @type {React.FunctionComponent<EditCTUploadTimeModalProps & import('./base/modal').ModalProps>}
 */
const EditCTUploadTimeModal = ({
  type = "uploadInterval",
  onCancel,
  intervalSettings,
  isLoading = false,
  onUpdateStatus,
  ...modalProps
}) => {
  const uploadTimeInput = useInput({
    defaultValue: intervalSettings[type],
    shouldDisplayError: () => true,
    validator: intValidator,
    validatorProps: {
      min: 1,
      max: 180,
    },
  })

  const updateButtonDisabled = isLoading || uploadTimeInput.error

  return (
    <Modal {...modalProps} onCloseClick={onCancel} closeDisabled={isLoading}>
      <ModalHeader>Edit Contact Tracer</ModalHeader>
      <ModalBody>
        <div className="edit-block">
          <NumberInput
            {...uploadTimeInput.getProps({
              required: true,
              tabIndex: "0",
              "aria-label": IntervalTitle[type] || "Upload Time",
              unitLabel: "Minutes",
              min: 5,
              max: 180,
            })}
          />
        </div>
      </ModalBody>
      <ModalFooter className="row">
        <OutlinedButton tabIndex="0" disabled={isLoading} onClick={onCancel}>
          Cancel
        </OutlinedButton>
        <Button
          tabIndex="0"
          disabled={updateButtonDisabled}
          width={160}
          onClick={() => {
            onUpdateStatus &&
              onUpdateStatus({
                [type]: parseInt(uploadTimeInput.value),
              })
          }}
          loading={isLoading}>
          Update Status
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditCTUploadTimeModal

/**
 * @typedef {object} EditCTUploadTimeModalProps
 * @property {IntervalType=} type
 * @property {() => void} onCancel
 * @property {boolean=} isLoading
 * @property {IntervalSettings} intervalSettings
 * @property {(param: UpdateIntervalParam) => void} onUpdateStatus
 */

/**
 * @typedef {import('../domains/ct-interval').UpdateIntervalParam} UpdateIntervalParam
 * @typedef {import('../domains/ct-interval').IntervalSettings} IntervalSettings
 * @typedef {import('../domains/ct-interval').IntervalType} IntervalType
 */
