export type WorkspaceSettingsDTO = {
  dailySurvey: {
    key: string
    survey_id: string
  }
  backToWorkSurvey: {
    key: string
    survey_id: string
  }
}

export interface WorkspaceSettings {
  dailySurvey?: DTSurveySettings
  backToWorkSurvey?: DTSurveySettings
}
export interface DTSurveySettings {
  apiKey?: string
  surveyId?: string
}

export const DTSurveySettingsType = {
  dailySurvey: "dailySurvey",
  backToWorkSurvey: "backToWorkSurvey",
}

export type UpdateDTSurveySettingsParams = {
  workspace: string
  surveyType: keyof typeof DTSurveySettingsType
} & DTSurveySettings
